import dynamic from "next/dynamic";
import Head from "next/head";
import { useRouter } from "next/router";

import { useEffect, useState } from "react";

import { appWithTranslation } from "next-i18next";
import TagManager from "react-gtm-module";
import "react-phone-input-2/lib/bootstrap.css";
import { QueryClient, QueryClientProvider } from "react-query";
import { v4 as uuid } from "uuid";

import Banner from "@components/banner";

import { pushToDataLayer } from "@lib/index";

import "../styles/style.scss";

const ConsentModal = dynamic(() => import("@components/consent"), {
  ssr: false,
});

function JobboardApp({ Component, pageProps }) {
  const [queryClient] = useState(() => new QueryClient());
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => {
      pushToDataLayer({ eventId: uuid() });
    };
    if (typeof window !== undefined) {
      TagManager.initialize({
        gtmId: "GTM-5QNPR89",
        dataLayer: {
          eventId: uuid(),
        },
      });
      pushToDataLayer("consent", "default", {
        ad_storage: "denied",
        analytics_storage: "denied",
      });

      // On page change we set a new eventId to be used in GTM
      router.events.on("routeChangeStart", handleRouteChange);
    }

    // If the component is unmounted, unsubscribe from router event
    return () => {
      router.events.off("routeChangeStart", handleRouteChange);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Head key="app-head">
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1.0, user-scalable=no"
        />
        <link rel="icon" href="/cropped-favicon-32x32.png" sizes="32x32" />
        <link rel="icon" href="/cropped-favicon-192x192.png" sizes="192x192" />
        <link rel="apple-touch-icon" href="/cropped-favicon-180x180.png" />
      </Head>
      <Banner />
      <QueryClientProvider client={queryClient}>
        <Component {...pageProps} />
      </QueryClientProvider>
      <ConsentModal />
    </>
  );
}

export default appWithTranslation(JobboardApp);
