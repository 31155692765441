export class Location {
  id: number;
  parent_id: number;
  slug: string;
  real_name: string;
  location_kind: string;
  nb_jobs: number;
  parent_real_name: string;

  constructor(locationData: Record<string, any>) {
    Object.assign(this, locationData);
  }

  /**
   * Return the location as a string, to be showed to the user.
   *   - if it's a city, we show:  city (state)
   *   - if it's a state, we show: state
   *
   * @param location
   * @returns
   */
  getLocationStr(): string {
    const locationStr = [`${this.real_name}`];
    if (this.location_kind === "city") {
      locationStr.push(` (${this.parent_real_name})`);
    }
    return locationStr.join("");
  }

  /**
   * @returns the country slug for the this.
   */
  extract_country_slug(): string {
    return this.slug.split("_")[0];
  }

  /**
   * @param _: list of countries.
   * @returns search url and the params for this
   */
  constructSearchUrlAndParams(countries: Record<string, Location>): {
    url: string;
    params: Record<string, string>;
  } {
    const country = countries[this.extract_country_slug()];

    const params: NodeJS.Dict<string> = {
      city: this.location_kind === "city" ? this.real_name : "",
      state: this.location_kind === "city" ? this.parent_real_name : this.real_name,
      country: country.real_name,
    };

    return {
      url: "search-jobs-v2",
      params: params,
    };
  }
}

export class GeolocateMe {
  lat: string;
  lon: string;
  real_name: string;

  constructor(locationData: Record<string, any>, real_name: string) {
    Object.assign(this, locationData);
    this.real_name = real_name;
  }

  /**
   * @returns the location as a string, to be showed to the user.
   */
  getLocationStr(): string {
    return this.real_name;
  }

  /**
   *
   * @param _: list of countries, useless here
   * @returns search url and the params for this
   */
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructSearchUrlAndParams(_: NodeJS.Dict<Location>): {
    url: string;
    params: Record<string, string>;
  } {
    return {
      url: "search-jobs-near-me",
      params: {
        lat: this.lat,
        lon: this.lon,
      },
    };
  }
}

export class JobSearchFormData {
  search: string;
  location: Location | GeolocateMe;

  constructor(search: string, location: Location | GeolocateMe) {
    Object.assign(this, { search, location });
  }
}
