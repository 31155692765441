import { parseUrl, stringifyUrl } from "query-string";
import { ParsedUrlQuery } from "querystring";

const query_string_keys = ["utm_campaign", "utm_source", "utm_medium", "consent", "strategy_id", "ref", "page_scope"];

export const preserve_query_strings = (
  url: string,
  parsed_url_query: ParsedUrlQuery,
  additional_path?: string
): string => {
  if (!url) {
    console.warn("tried to parse empty url");
    return;
  }
  const parsed = parseUrl(url);

  query_string_keys.map((key) => {
    // only add the param if it's not already in the url
    if (parsed_url_query[key] && parsed.query[key] === undefined) {
      parsed.query[key] = parsed_url_query[key];
    }
  });

  // This is used to add new parts to the url
  // while preserving the query
  let baseUrl = parsed.url;
  if (additional_path) {
    if (baseUrl[baseUrl.length - 1] === "/") {
      baseUrl = baseUrl.slice(0, -1);
    }
    baseUrl += additional_path;
  }

  return stringifyUrl({
    ...parsed,
    url: baseUrl,
  });
};
