import { NextRouter } from "next/router";

import { ParsedUrlQuery } from "querystring";

import { myGeolocationURL } from "@lib/constants";

import { GeolocateMe, Location } from "@entities/location";

import { preserve_query_strings } from "./url";

/**
 * Data Layer is a JavaScript array that temporarily stores the information you need
 * and then Google Tag Manager uses that data in tag/triggers/variables
 * (later that data can be transferred to other tools, like Google Analytics).
 *
 * @param args all the things you want to push
 * @returns nothing
 */
export function pushToDataLayer(...args: any): void {
  if (window === undefined) {
    return;
  }
  window["dataLayer"] = window["dataLayer"] || [];
  window["dataLayer"].push(...args);
}

/**
 * For both type of locations, redirect the user to the search_result page,
 * after he submitted his form.
 *
 * The search_result url will contains different information depending on the type
 * of location that we get.
 *
 * It gracefully handles reload or not, depending on what page we are on when we
 * do the search. (we don't want to reload if we are already on the search page.)
 * @param selectedLocation
 * @param search
 * @param router
 * @returns
 */
export function redirectToNewLocation(
  selectedLocation: Location | GeolocateMe,
  search: string,
  router: NextRouter
): void {
  if (!selectedLocation) {
    return;
  }

  // eslint-disable-next-line prefer-const
  let { pathname, query } = router;
  let newUrl = null;

  if (selectedLocation instanceof GeolocateMe) {
    newUrl = preserve_query_strings(
      `/search_result/${myGeolocationURL as string}?lat=${selectedLocation.lat}&lon=${
        selectedLocation.lon
      }&search=${search}`,
      query
    );
  } else {
    newUrl = preserve_query_strings(`/search_result/${selectedLocation.slug}?search=${search}`, query);
  }

  // if we aren't currently on the search result page, we change our pathname
  // to it. This will effectively change the page and load the new one (i.e the
  // search page.
  // if we are already on the page, we keep the pathname to the same but we change
  // the `as` value (second param of push), which will change the url in the
  // browser, but not re-load the component.
  if (!pathname.includes("search_result") && !pathname.includes("city")) {
    pathname = newUrl;
  }
  router.push({ pathname, query }, newUrl, { shallow: true });
}

/**
 * Do a fetch and handle the json parse and error possibility all here.
 * all you have to do is pass the url and your fn callback function that will
 * process the data on success.
 *
 * @param url
 * @param fn: will be called with your JSON data parsed
 */
export async function fetchAndParse(url: string, fn: (data: any) => any, catch300?: boolean): Promise<any> {
  catch300 = catch300 || false;

  return await fetch(url)
    .then(async (response) => {
      // if the HTTP code is 300 or more, we say it's invalid because
      // we only expect 200 and django returns a 300 if the url is missing a /
      // at the end of the url, not a 404, and we want to catch that.
      if (!response.ok || response.status >= 300) {
        if ((catch300 && response.status >= 400) || !catch300) {
          throw new Error(`${response.status} - ${await response.text()}`);
        }
      } else {
        try {
          return await response.json();
        } catch (err) {
          const body = await response.json();
          throw new Error(`${body as string} - ${err as string}`);
        }
      }
    })
    .then(fn)
    .catch((error) => {
      console.error("fetching or parsing failed: ", error);
    });
}

export function QueryParamsToString(query: ParsedUrlQuery): NodeJS.Dict<string> {
  const res = {};
  Object.entries(query).forEach(([key, value]) => {
    if (value instanceof Array) {
      res[key] = value.join(" ");
    } else {
      res[key] = value;
    }
  });
  return res;
}

/**
 * Return true if subStr is included in str.
 * We don't use the `String.includes` method because it doesn't actually really
 * check for inclusions, but it checks if a string is a substring of another.
 *
 * Our inclusion here is true if all the characters of subStr are included in
 * the str, no matter if they are all side to side. The order of their
 * appearance in subStr is still important thought.
 *
 * So containSubString('california san francisco', 'cali fran') == true
 *    (when calling it, do remove spaces to make the search fuzzier).
 *
 * complexity is O(len(str))
 *
 * @param str
 * @param subStr
 * @returns
 */
export function containSubString(str: string, subStr: string): boolean {
  if (!str || !subStr) {
    return false;
  }
  // return true if the subStr is longer than the str.
  // this prevent users from putting super long string and making
  // it costly.
  if (subStr.length > str.length) {
    return false;
  }

  // i is the current substring character we are looking at.
  // we we find character matching in str, we make it move to the right
  // of the substring, until we've made sure that it's completely included in str.
  let i = 0;
  const l = subStr.length;

  for (const c of str) {
    if (c == subStr.charAt(i)) {
      i += 1;
      if (i == l) {
        return true;
      }
    }
  }
  return false;
}

export const capitalize = (str: string): string => {
  return str ? `${str.charAt(0).toUpperCase()}${str.slice(1)}` : "";
};
