export const countriesFlags = {
  australia: "🇦🇺",
  belgium: "🇧🇪",
  canada: "🇨🇦",
  france: "🇫🇷",
  germany: "🇩🇪",
  india: "🇮🇳 ",
  italy: "🇮🇹",
  luxembourg: "🇱🇺",
  portugal: "🇵🇹",
  spain: "🇪🇸",
  switzerland: "🇨🇭",
  "the-netherlands": "🇳🇱",
  "united-kingdom": "🇬🇧",
  "united-states": "🇺🇸",
};

export const myGeolocationURL = "my_location";

export const languagesCountries = {
  "en-US": "united-states",
  "en-UK": "united-kingdom",
  it: "italy",
  fr: "france",
  nl: "the-netherlands",
  de: "germany",
};
