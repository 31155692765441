import { i18n } from "next-i18next";

const LANGS = ["de", "en-US", "en-UK", "fr", "it", "nl"];
export const loadTranslations = (name: string): void => {
  LANGS.forEach((lang) => {
    try {
      i18n.addResourceBundle(lang, name, require(`../i18n/${lang}/${name}.json`));
    } catch (err) {
      // pass
    }
  });
};
