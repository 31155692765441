import Image from "next/image";
import Link from "next/link";
import { useRouter } from "next/router";

import { FC, useState } from "react";

import { useTranslation } from "next-i18next";
import { Container, Nav, Navbar, NavDropdown } from "react-bootstrap";

import { loadTranslations } from "@lib/i18n";
import { preserve_query_strings } from "@lib/url";

import logo from "../public/work4.webp";

const Banner: FC = () => {
  const { t, i18n } = useTranslation("jobboard_banner");
  loadTranslations("jobboard_banner");
  const [isOpened, setOpened] = useState(false);
  const [dropdownTitle, setDropdownTitle] = useState(t("jobboard_banner:current_language"));
  const router = useRouter();

  /**
   * Changes the title of the dropdown depending on its state, whether it's
   * toggled on or not. When toggling it on, we set it to "Choose a language",
   * and when toggling it off, we set it to the current chosen language.
   */
  const updateDropdownTitleTextOnToggle = () => {
    setDropdownTitle(t(isOpened ? "jobboard_banner:current_language" : "jobboard_banner:banner_language_choice"));
    setOpened(!isOpened);
  };
  return (
    <Navbar bg="light" fixed="top">
      <Container>
        <Navbar.Brand>
          <Link href={preserve_query_strings("/", router.query)} passHref={true}>
            <a>
              <Image
                src={logo}
                className="d-inline-block align-top logo"
                alt="Work4"
                layout="fixed"
                height={27}
                width={100}
              />
            </a>
          </Link>
        </Navbar.Brand>
        <Nav>
          <Container>
            <NavDropdown title={dropdownTitle} id="basic-nav-dropdown" onToggle={updateDropdownTitleTextOnToggle}>
              {Object.entries({
                de: "🇩🇪 Deutsch",
                "en-UK": "🇬🇧 English (UK)",
                "en-US": "🇺🇸 English (US)",
                fr: "🇫🇷 Français",
                it: "🇮🇹 Italiano",
                nl: "🇳🇱 Nederlands",
              }).map(([key, value], index) => (
                <NavDropdown.Item
                  key={index}
                  onSelect={() => {
                    // Change the current language
                    i18n.changeLanguage(key);

                    // But also change it in the router, so that we can
                    // load the correct page and language with server side
                    // rendering
                    const { pathname, asPath, query } = router;
                    router.push({ pathname, query }, asPath, {
                      locale: key,
                    });

                    setDropdownTitle(t("jobboard_banner:current_language"));
                  }}
                >
                  {value}
                </NavDropdown.Item>
              ))}
            </NavDropdown>
          </Container>
        </Nav>
      </Container>
    </Navbar>
  );
};
export default Banner;
