import { Location } from "@entities/location";

import { fetchAndParse } from "./utils";

// caches the countries response.
// This will stay in memory until the user refresh the page.
// if he just change a url without a reload, this will work.
// We could have a longer cache, but then it will be harder to invalidate it.
// for now this is enough.
export let respFetchCountries: NodeJS.Dict<Location> = null;

/**
 * A function to reset the cached repsonse of the country query.
 * It's a bit of an anti-pattern to have this because we should just use this in test
 * but exported variables are not updatable from other modules, so we can't do that
 * in another file. An alternative solution would be to make respFetchCountries an array/object
 * where we could always update the 0 index/property, but it's not better either.
 */
export function resetCountryCachedResponse(): void {
  respFetchCountries = null;
}

/**
 * Returns the list of countries that have location in our backend.
 * The result is semi-cahed with the `respFetchCountries` variable, cf comment.
 * @returns {
 * ""
 * "countries": {
 *     "france": {
 *         "id": 2,
 *         "location_kind": "country",
 *         "parent_id": null,
 *         "real_name": "France",
 *         "slug": "france"
 *     },
 *     ...
 * }
 * }
 */
export async function fetchCountries(): Promise<NodeJS.Dict<Location>> {
  if (respFetchCountries !== null) return respFetchCountries;

  return await fetchAndParse(`${process.env.NEXT_PUBLIC_JOBBOARD_LOCATION_URI}/countries`, parseCountriesData);
}

export async function fetchCountriesData(): Promise<Response> {
  return await fetch(`${process.env.NEXT_PUBLIC_JOBBOARD_LOCATION_URI}/countries`);
}

export function parseCountriesData(data): NodeJS.Dict<Location> {
  respFetchCountries = {};
  Object.entries(data).forEach(([key, value]) => {
    respFetchCountries[key] = new Location(value);
  });
  return respFetchCountries;
}

/**
 *
 * @returns the users's country, where he is currently.
 */
export async function fetchUserCountry(): Promise<string> {
  return await fetchAndParse(`${process.env.NEXT_PUBLIC_JOBBOARD_LOCATION_URI}/user_country`, (data) => {
    return data;
  });
}

/**
 *
 * @param selectedCountrySlug
 * @param search
 *
 * @returns cities and states that match the search and belong to the country
 */
export async function searchCitiesAndStates(selectedCountrySlug: string, search: string): Promise<Location[]> {
  return await fetchAndParse(
    `${process.env.NEXT_PUBLIC_JOBBOARD_LOCATION_URI}/countries/${selectedCountrySlug}/cities-and-states?search=${search}`,
    (data) => {
      return data.map((location) => new Location(location));
    }
  );
}

/**
 *  @returns the most popular (by nb of jobs) of the country
 */
export async function fetchPopularCities(selectedCountrySlug: string): Promise<Location[]> {
  return await fetchAndParse(
    `${process.env.NEXT_PUBLIC_JOBBOARD_LOCATION_URI}/countries/${selectedCountrySlug}/popular-cities`,
    (data) => {
      return data.map((location) => new Location(location));
    }
  );
}

// Returns an array of 0 or 1 element for the slug.
export async function fetchLocationData(locationSlug: string): Promise<Response> {
  // TODO handle if locationSlug is invalid
  return await fetch(`${process.env.NEXT_PUBLIC_JOBBOARD_LOCATION_URI}/location/${locationSlug}`);
}

export function parseLocationData(data: Array<Location>): Location {
  if (!data || data.length !== 1) return null;

  return new Location(data[0]);
}
